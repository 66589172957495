<mat-card class="w-50 mx-auto my-5 fade-in" *ngIf="showRegisterCourse">
  <mat-card-header>
    <mat-card-title>
      Registrar curso nuevo
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field class="d-block m-3">
      <mat-label>Nombre del curso</mat-label>
      <input [(ngModel)]="grade_name"
      [ngModelOptions]="{standalone: true}" matInput>
    </mat-form-field>
    <mat-form-field class="d-block m-3">
      <mat-label>Id Coordinador</mat-label>
      <input disabled [(ngModel)]="moderator_id"
      [ngModelOptions]="{standalone: true}" matInput>
    </mat-form-field>
    <div class="text-center m-3">
      <button class="ml-auto mr-2 mb-2" mat-flat-button color="warn" (click)="hideRegisterGrade()">Cancelar</button>
      <button class="mr-auto ml-2" mat-flat-button color="primary" (click)="registerGrade()">Registrar</button>
    </div>
  </mat-card-content>
</mat-card>
<div class="col-12 text-right">
  <button *ngIf="!showRegisterCourse" class="add-button m-3 fade-in"
  mat-flat-button color="primary" (click)="showRegisterGrade()">
  <mat-icon class="mr-3">group_add</mat-icon>Agregar curso
  </button>
</div>


<mat-card class="mx-auto col-lg-4 col-md-12 col-sm-12 my-5 fade-in">
  <mat-card-header>
    <mat-card-title>
      <mat-label>Centro:</mat-label>
      <input disabled [(ngModel)]="moderator_institution"
      [ngModelOptions]="{standalone: true}" matInput>
    </mat-card-title>
    <mat-card-title>Seleccionar curso</mat-card-title>
  </mat-card-header>
  <mat-card-content class="text-center p-2">
    <mat-form-field>
      <mat-label>Cursos</mat-label>
      <mat-select  [(ngModel)]="grade_id" [ngModelOptions]="{standalone: true}">
        <mat-option (click)="loadSellerList()" *ngFor="let course of grade" [value]="course.grade_id">{{course.grade_name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button (click)="deleteGrade()" *ngIf="grade_id != null" color="warn" class="ml-2" mat-icon-button><mat-icon>delete</mat-icon></button>
  </mat-card-content>
</mat-card>
<mat-card class="fade-in proviaje-last-item" *ngIf="grade_id != null">
  <mat-card-header>
    <mat-card-title>Lista de estudiantes</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="table-container">
      <app-table-users (toggleStatusEvent)="toggleSellerStatusEvent($event)" (deleteEvent)="deleteSellerEvent($event)" [grades]="grade_id" [sellerList]="sellerList" [userType]="userType"></app-table-users>
    </div>
  </mat-card-content>
</mat-card>
