import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';


import { DataMasterService, USER_TYPE_USER, USER_TYPE_MOD, USER_TYPE_ADMIN } from 'src/app/services/data-master.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email : string;
  password : string;
  titulo : string;
  userType : string;
  recovering : boolean = false;

  recoverGroup : FormGroup;

  constructor( private dataService : DataMasterService,
    private activatedRoute: ActivatedRoute, private router : Router,
    private location : Location, private formBuilder : FormBuilder) {

    this.recoverGroup = formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.activatedRoute.params.subscribe(params =>{

      if (params.type != null) {
        this.userType = params.type;
        switch(params.type) {
          case USER_TYPE_USER: {
            this.titulo = "Alumno";
            this.dataService.getWebStatus().subscribe(responseWeb =>{
              if (responseWeb[1][0].status != "0") {
                this.webNotFound();
              }
            });
            break;
          }
          case USER_TYPE_MOD: {
            this.titulo = "Coordinador";
            this.dataService.getWebStatus().subscribe(responseWeb =>{
              if (responseWeb[1][0].status != "0") {
                this.webNotFound();
              }
            });
            break;
          }
          case USER_TYPE_ADMIN: {
            this.titulo = "Administrador";
            break;
          }
          default: {
            console.error("Tipo de usuario inexistente");
            this.dataService.getWebStatus().subscribe(responseWeb =>{
              if (responseWeb[1][0].status != "0") {
                this.webNotFound();
              }
            });
          }
        }
      }
    });
  }

  // variable
  show_button: Boolean = false;
  show_eye: Boolean = false;

//Function
showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }


  ngOnInit() : void {
    this.dataService.setUserType('');
    sessionStorage.removeItem('session_token');
    sessionStorage.removeItem('cart_list');
    sessionStorage.removeItem('cart_quantity_list');
    sessionStorage.removeItem('moderator_request_list');
  }

  webNotFound() {
    this.dataService.showMessage('Web en mantenimiento', '', 'warning');
    this.router.navigate(['/info']);
  }

  login() : void {
    this.dataService.login(this.email, this.password, this.userType).subscribe((response) => {
     //console.log(response);

      if (!response[0]) {
         console.error("error", response);
      } else {

        if (response[1] == "usuario invalido") {
          this.dataService.showMessage({
            icon: 'error',
            title: 'Error de inicio de sesion',
            html: '<h1 style="color:red"><b>E-mail Incorrecto</b></h1>',
        });}
         else if (response[1] == "password invalida") {
          this.dataService.showMessage({
            icon: 'error',
            title: 'Error de inicio de sesion',
            html: '<h1 style="color:red"><b>Contraseña incorrecta</b></h1>',
        });}
         else if (response[1]  == "usuario desabilitado") {
          this.dataService.showMessage('Usuario Deshabilitado', 'Porfavor, consulta con tu coordinador.', 'error');
        } else {
          //console.log("Success token");

          sessionStorage.setItem('session_token', response[1].toString());
          this.dataService.setUserType(this.userType);
          this.router.navigate(['/' + this.userType, 'home']);
          
          let localId = this.dataService.getUserId();
          if (this.userType == "user") {
            this.dataService.insertLog(localId, null, null, "Inicio de sesión exitoso").subscribe(responseLog=>{
              console.log(responseLog);
            });
          }else if(this.userType == "mod"){
            this.dataService.insertLog(null, localId, null, "Inicio de sesión exitoso").subscribe(responseLog=>{
              console.log(responseLog);
            });
          }else{
            this.dataService.insertLog(null, null, localId, "Inicio de sesión exitoso").subscribe(responseLog=>{
              console.log(responseLog);
            });
          }
          
        }
      }
    });
  }

  sendRecover() {
    if (this.recoverGroup.invalid) {
      return;
    }

    let values = {};
    if (this.userType == USER_TYPE_USER) {
       values['seller_email'] = this.recoverGroup.value.email;
    } else if (this.userType == USER_TYPE_MOD) {
      values['moderator_email'] = this.recoverGroup.value.email;
    }
    console.log("Inicio recuperación");

    this.dataService.sendRecover(values).subscribe(({ result } : any) => {
      console.log("Respuesta", result);
      if (!result) {
         this.dataService.showMessage('El email ingresado no esta registrado', '', 'error');
         return;
      }
      this.dataService.showMessage('Tu peticion ha sido enviada', 'En breve se te enviara un email con un enlace mediante el cual podras cambiar tu contraseña.', 'success');
      this.recovering = false;
    }, error =>{
      console.log(error);
      this.dataService.showMessage('No se pudo enviar el correo', '', 'error');
      return;
    });
  }

  openRecover(event) {
    event.preventDefault();
    this.recovering = true;
  }

  backLocation() : void {
    this.location.back();
  }

}
