
<!--<div class="d-flex justify-content-center align-items-center" style="height:50vh;">
    <div class="col-md-6">
        <h1 class="text-center mb-4">{{text}}</h1>
        <div class="custom-control custom-switch custom-switch-xl text-center">
            <input type="checkbox" class="custom-control-input" id="customSwitch1" [(ngModel)]="active" (change)="onToggle($event)">
            <label class="custom-control-label" for="customSwitch1"></label>
        </div>
    </div>
</div>-->

<mat-card class="col-lg-6 col-md-12 col-sm-12 mx-auto fade-in proviaje-last-item">
    <mat-card-header>
      <mat-card-title>Parametros</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form class="p-3" (ngSubmit)="updateParameters()">
        <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
          <mat-label>Versión App Movil</mat-label>
          <input type="text" matInput name="name" required [(ngModel)]="appVersion">
        </mat-form-field>
        
  
        <div>
          <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
            <mat-label>Estatus web</mat-label>
            <mat-select  [(ngModel)]="active" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let status of activeOptions" [value]="status.value">
                    {{ status.label }}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        
  
        <div>
            <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
                <mat-label>Inactivar alumnos</mat-label>
                <mat-select  [(ngModel)]="disabledPerson" [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let status of disabledOptions" [value]="status.value">
                        {{ status.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        
  
        <button class="m-3" mat-flat-button color="primary">Actualizar datos</button>
      </form>
    </mat-card-content>
  </mat-card>
  
