import { Component, OnInit } from '@angular/core';
import { DataMasterService } from 'src/app/services/data-master.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-web-status',
  templateUrl: './admin-web-status.component.html',
  styleUrls: ['./admin-web-status.component.scss']
})
export class AdminWebStatusComponent implements OnInit {
  active = 0;
  text = "Web inactiva";
  appVersion = "";
  disabledPerson = 0;
  activeOptions = [
    { value: 0, label: 'Activa' },
    { value: 1, label: 'Inactiva' }
  ];

  disabledOptions = [
    { value: 0, label: 'Activo' },
    { value: 1, label: 'Inactivo' }
  ];

  constructor(private dataService : DataMasterService) { }

  ngOnInit(): void {
    this.loadWebStatus();
  }

  loadWebStatus(){
    this.dataService.getWebStatus().subscribe(responseWeb => {
      console.log("Web",responseWeb);
      if (!responseWeb[0]) {
        console.error(responseWeb[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      if (responseWeb[1] == "no result") {

        this.dataService.showMessage('Debe tener un registro en la tabla webstatus.', '', 'warning');
        
      } else {
        this.appVersion = responseWeb[1][0].version;
        this.disabledPerson = parseInt(responseWeb[1][0].disabled);
        if (responseWeb[1][0].status == '1') {
          this.active = 1;
          this.text = "Web inactiva";
        }else{
          this.active = 0;
          this.text = "Web activa";
        }

      }
    });
  }
  /*onToggle(event: any) {
    console.log(this.active);
    if (this.active == true) {
      this.text = "Web activa";
      this.dataService.updateWebStatus(0).subscribe(responseUpdate =>{
        console.log(responseUpdate);
        this.dataService.showMessage('La web fue habilidata correctamente', '', 'info');
        this.dataService.insertLog(null, null, this.dataService.getUserId(), "Web habilitada correctamente").subscribe(responseLog=>{
          console.log(responseLog);
        });
      });
    }else{
      this.text = "Web inactiva";
      this.dataService.updateWebStatus(1).subscribe(responseUpdate =>{
        console.log(responseUpdate);
        this.dataService.showMessage('La web se ha puesto en manteminento', '', 'info');
        this.dataService.insertLog(null, null, this.dataService.getUserId(), "Web en mantenimiento").subscribe(responseLog=>{
          console.log(responseLog);
        });
      });
    }
  }*/

  updateParameters(){
    this.dataService.updateWebStatus(this.active, this.appVersion,this.disabledPerson).subscribe(responseUpdate =>{
      console.log(responseUpdate);
      this.dataService.showMessage('Cambios realizados correctamente', '', 'info');
      this.dataService.insertLog(null, null, this.dataService.getUserId(), "Web habilitada correctamente").subscribe(responseLog=>{
        console.log(responseLog);
      });
    });
  }

}
